import { useDebouncedCallback } from 'use-debounce';

import clsx from 'clsx';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IssueBanner } from '@root/infra/layout/components/issue-banner';
import { GenieGptIcon } from '@root/shared/icons/genie-gpt-icon';
import { SendIcon } from '@root/shared/icons/send-icon';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';
import { useLogEvent } from '@root/shared/utils/hooks/use-log-event';

import { LikeIcon } from '../../../shared/icons/like-icon';
import { ChatCommandPrefix, useChatCommand, useCommand } from '../command';
import { CHAT_PAGE_SIZE, LAST_INPUT_KEY, REQUEST_TIMEOUT_MS, UNFINISHED_INPUT } from '../constant';
import { ChatMessage, SubmitKey, useAccessStore, useAppConfig, useChatStore } from '../store';
import { Prompt, usePromptStore } from '../store/prompt';
import { Message } from '../types/chat';
import { autoGrowTextArea, selectOrCopy, useMobileScreen } from '../utils';
import { prettyObject } from '../utils/format';
import styles from './chat.module.scss';
import { EmptyChat } from './empty-chat';
import { Markdown } from './markdown';
import { QuickPrompts } from './quick-prompts';
import { showConfirm } from './ui-lib';

function useSubmitHandler() {
  const submitKey = SubmitKey.Enter;
  const isComposing = useRef(false);

  useEffect(() => {
    const onCompositionStart = () => {
      isComposing.current = true;
    };
    const onCompositionEnd = () => {
      isComposing.current = false;
    };

    window.addEventListener('compositionstart', onCompositionStart);
    window.addEventListener('compositionend', onCompositionEnd);

    return () => {
      window.removeEventListener('compositionstart', onCompositionStart);
      window.removeEventListener('compositionend', onCompositionEnd);
    };
  }, []);

  const shouldSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== 'Enter') return false;
    if (e.key === 'Enter' && (e.nativeEvent.isComposing || isComposing.current)) return false;
    return (
      // (submitKey === SubmitKey.AltEnter && e.altKey) ||
      // (submitKey === SubmitKey.CtrlEnter && e.ctrlKey) ||
      // (submitKey === SubmitKey.ShiftEnter && e.shiftKey) ||
      // (submitKey === SubmitKey.MetaEnter && e.metaKey) ||
      submitKey === SubmitKey.Enter && !e.altKey && !e.ctrlKey && !e.shiftKey && !e.metaKey
    );
  };

  return {
    submitKey,
    shouldSubmit,
  };
}

export type RenderPompt = Pick<Prompt, 'title' | 'content'>;

export function PromptHints(props: { prompts: RenderPompt[]; onPromptSelect: (prompt: RenderPompt) => void }) {
  const noPrompts = props.prompts.length === 0;
  const [selectIndex, setSelectIndex] = useState(0);
  const selectedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectIndex(0);
  }, [props.prompts.length]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (noPrompts || e.metaKey || e.altKey || e.ctrlKey) {
        return;
      }
      // arrow up / down to select prompt
      const changeIndex = (delta: number) => {
        e.stopPropagation();
        e.preventDefault();
        const nextIndex = Math.max(0, Math.min(props.prompts.length - 1, selectIndex + delta));
        setSelectIndex(nextIndex);
        selectedRef.current?.scrollIntoView({
          block: 'center',
        });
      };

      if (e.key === 'ArrowUp') {
        changeIndex(1);
      } else if (e.key === 'ArrowDown') {
        changeIndex(-1);
      } else if (e.key === 'Enter' && e.shiftKey) {
        const selectedPrompt = props.prompts.at(selectIndex);
        if (selectedPrompt) {
          props.onPromptSelect(selectedPrompt);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prompts.length, selectIndex]);

  if (noPrompts) return null;
  return (
    <div className={styles['prompt-hints']}>
      {props.prompts.map((prompt, i) => (
        <div
          ref={i === selectIndex ? selectedRef : null}
          className={styles['prompt-hint'] + ` ${i === selectIndex ? styles['prompt-hint-selected'] : ''}`}
          key={prompt.title + i.toString()}
          onClick={() => props.onPromptSelect(prompt)}
          onMouseEnter={() => setSelectIndex(i)}
        >
          <div className={styles['hint-title']}>{prompt.title}</div>
          <div className={styles['hint-content']}>{prompt.content}</div>
        </div>
      ))}
    </div>
  );
}

function ClearContextDivider() {
  const chatStore = useChatStore();
  const { t } = useTranslation('master-chat');

  return (
    <div className={styles['clear-context']} onClick={() => chatStore.updateCurrentSession((session) => (session.clearContextIndex = undefined))}>
      <div className={styles['clear-context-tips']}>{t('Context.Clear')}</div>
      <div className={styles['clear-context-revert-btn']}>{t('Context.Revert')}</div>
    </div>
  );
}

function ChatAction(props: { text: string; icon: JSX.Element; filled?: boolean; onClick: () => void }) {
  const iconRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState({
    full: 16,
    icon: 16,
  });

  function updateWidth() {
    if (!iconRef.current || !textRef.current) return;
    const getWidth = (dom: HTMLDivElement) => dom.getBoundingClientRect().width;
    const textWidth = getWidth(textRef.current);
    const iconWidth = getWidth(iconRef.current);
    setWidth({
      full: textWidth + iconWidth,
      icon: iconWidth,
    });
  }

  return (
    <div
      className={`${styles['chat-input-action']} clickable ${props.filled ? styles['chat-input-action--filled'] : ''}`}
      onClick={() => {
        props.onClick();
        setTimeout(updateWidth, 1);
      }}
      onMouseEnter={updateWidth}
      onTouchStart={updateWidth}
      style={
        {
          '--icon-width': `${width.icon}px`,
          '--full-width': `${width.full}px`,
        } as React.CSSProperties
      }
    >
      <div ref={iconRef} className={styles['icon']}>
        {props.icon}
      </div>
      <div className={styles['text']} ref={textRef}>
        {props.text}
      </div>
    </div>
  );
}

function useScrollToBottom() {
  // for auto-scroll
  const scrollRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);

  function scrollDomToBottom() {
    const dom = scrollRef.current;
    if (dom) {
      requestAnimationFrame(() => {
        setAutoScroll(true);
        dom.scrollTo(0, dom.scrollHeight);
      });
    }
  }

  // auto scroll
  useEffect(() => {
    if (autoScroll) {
      scrollDomToBottom();
    }
  });

  return {
    scrollRef,
    autoScroll,
    setAutoScroll,
    scrollDomToBottom,
  };
}

function _Chat() {
  type RenderMessage = ChatMessage & { preview?: boolean };

  const chatStore = useChatStore();
  const session = chatStore.currentSession();
  const config = useAppConfig();
  const fontSize = config.fontSize;

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [userInput, setUserInput] = useState('');
  const [, setIsLoading] = useState(false);
  const { shouldSubmit } = useSubmitHandler();
  const { scrollRef, setAutoScroll, scrollDomToBottom } = useScrollToBottom();
  const [, setHitBottom] = useState(true);
  const isMobileScreen = useMobileScreen();

  const { logEvent } = useLogEvent();

  const { t, i18n } = useTranslation('master-chat');

  // prompt hints
  const promptStore = usePromptStore();
  const [promptHints, setPromptHints] = useState<RenderPompt[]>([]);
  const onSearch = useDebouncedCallback(
    (text: string) => {
      const matchedPrompts = promptStore.search(text);
      setPromptHints(matchedPrompts);
    },
    100,
    { leading: true, trailing: true },
  );

  // auto grow input
  const [inputRows, setInputRows] = useState(2);
  const measure = useDebouncedCallback(
    () => {
      const rows = inputRef.current ? autoGrowTextArea(inputRef.current) : 1;
      const inputRows = Math.min(20, Math.max(1 + Number(!isMobileScreen), rows));
      setInputRows(inputRows);
    },
    100,
    {
      leading: true,
      trailing: true,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(measure, [userInput]);

  // chat commands shortcuts
  const chatCommands = useChatCommand({
    // new: () => chatStore.newSession(i18n.language),
    // newm: () => navigate(Path.NewChat),
    // prev: () => chatStore.nextSession(-1),
    // next: () => chatStore.nextSession(1),
    // clear: () => chatStore.updateCurrentSession((session) => (session.clearContextIndex = session.messages.length)),
    // del: () => chatStore.deleteSession(chatStore.currentSessionIndex, t, i18n.language),
  });

  // only search prompts when user input is short
  const SEARCH_TEXT_LIMIT = 30;
  const onInput = (text: string) => {
    setUserInput(text);
    const n = text.trim().length;

    // clear search results
    if (n === 0) {
      setPromptHints([]);
    } else if (text.startsWith(ChatCommandPrefix)) {
      setPromptHints(chatCommands.search(text));
    } else if (!config.disablePromptHint && n < SEARCH_TEXT_LIMIT) {
      // check if need to trigger auto completion
      if (text.startsWith('/')) {
        const searchText = text.slice(1);
        onSearch(searchText);
      }
    }
  };

  const doSubmit = (userInput: string) => {
    if (userInput.trim() === '') return;
    const matchCommand = chatCommands.match(userInput);

    if (matchCommand.matched) {
      setUserInput('');
      setPromptHints([]);
      matchCommand.invoke();
      return;
    }
    setIsLoading(true);
    chatStore
      .onUserInput(userInput, t, i18n.language, logEvent)
      .then(() => setIsLoading(false))
      .catch((e) => {
        setIsLoading(false);
        if (e?.message === 'message_rate_limit') {
          logEvent('message_rate_limit', { chat_id: session.id });
        }
      });
    localStorage.setItem(LAST_INPUT_KEY, userInput);
    setUserInput('');
    setPromptHints([]);
    if (!isMobileScreen) inputRef.current?.focus();
    setAutoScroll(true);
  };

  const onPromptSelect = (prompt: RenderPompt) => {
    setTimeout(() => {
      setPromptHints([]);

      const matchedChatCommand = chatCommands.match(prompt.content);
      if (matchedChatCommand.matched) {
        // if user is selecting a chat command, just trigger it
        matchedChatCommand.invoke();
        setUserInput('');
      } else {
        // or fill the prompt
        setUserInput(prompt.content);
      }
      inputRef.current?.focus();
    }, 30);
  };

  useEffect(() => {
    chatStore.updateCurrentSession((session) => {
      const stopTiming = Date.now() - REQUEST_TIMEOUT_MS;
      session.messages.forEach((m) => {
        // check if should stop all stale messages
        if (m.isError || new Date(m.date).getTime() < stopTiming) {
          if (m.streaming) {
            m.streaming = false;
          }

          if (m.message?.content.length === 0) {
            m.isError = true;
            m.message = prettyObject({
              error: true,
              message: 'empty response',
            });
          }
        }
      });

      // auto sync mask config from global config
      if (session.mask.syncGlobalConfig) {
        console.log('[Mask] syncing from global, name = ', session.mask.name);
        session.mask.modelConfig = { ...config.modelConfig };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRightClick = (e, message: ChatMessage) => {
    // copy to clipboard
    if (selectOrCopy(e.currentTarget, message.message?.content || '', t)) {
      if (userInput.length === 0) {
        setUserInput(message.message?.content || '');
      }

      e.preventDefault();
    }
  };

  const context: RenderMessage[] = useMemo(() => {
    return session.mask.hideContext ? [] : session.mask.context.slice();
  }, [session.mask.context, session.mask.hideContext]);
  const accessStore = useAccessStore();

  // preview messages
  const renderMessages = useMemo(() => {
    return context.concat(session.messages as RenderMessage[]);
  }, [context, session.messages]);

  const [msgRenderIndex, _setMsgRenderIndex] = useState(Math.max(0, renderMessages.length - CHAT_PAGE_SIZE));

  function setMsgRenderIndex(newIndex: number) {
    newIndex = Math.min(renderMessages.length - CHAT_PAGE_SIZE, newIndex);
    newIndex = Math.max(0, newIndex);
    _setMsgRenderIndex(newIndex);
  }

  const messages = useMemo(() => {
    const endRenderIndex = Math.min(msgRenderIndex + 3 * CHAT_PAGE_SIZE, renderMessages.length);
    return renderMessages.slice(msgRenderIndex, endRenderIndex);
  }, [msgRenderIndex, renderMessages]);

  const botMessageLoading = useMemo(() => !!messages.filter((m) => m.role !== 'user').find((m) => m.streaming), [messages]);

  // check if should send message
  const onInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // if ArrowUp and no userInput, fill with last input
    if (e.key === 'ArrowUp' && userInput.length <= 0 && !(e.metaKey || e.altKey || e.ctrlKey)) {
      setUserInput(localStorage.getItem(LAST_INPUT_KEY) ?? '');
      e.preventDefault();
      return;
    }
    if (shouldSubmit(e) && !botMessageLoading && promptHints.length === 0) {
      doSubmit(userInput);
      e.preventDefault();
    }
  };

  const onChatBodyScroll = (e: HTMLElement) => {
    const bottomHeight = e.scrollTop + e.clientHeight;
    const edgeThreshold = e.clientHeight;

    const isTouchTopEdge = e.scrollTop <= edgeThreshold;
    const isTouchBottomEdge = bottomHeight >= e.scrollHeight - edgeThreshold;
    const isHitBottom = bottomHeight >= e.scrollHeight - (isMobileScreen ? 4 : 10);

    const prevPageMsgIndex = msgRenderIndex - CHAT_PAGE_SIZE;
    const nextPageMsgIndex = msgRenderIndex + CHAT_PAGE_SIZE;

    if (isTouchTopEdge && !isTouchBottomEdge) {
      setMsgRenderIndex(prevPageMsgIndex);
    } else if (isTouchBottomEdge) {
      setMsgRenderIndex(nextPageMsgIndex);
    }

    setHitBottom(isHitBottom);
    setAutoScroll(isHitBottom);
  };

  function scrollToBottom() {
    setMsgRenderIndex(renderMessages.length - CHAT_PAGE_SIZE);
    scrollDomToBottom();
  }

  // clear context index = context length + index in messages
  const clearContextIndex = (session.clearContextIndex ?? -1) >= 0 ? session.clearContextIndex! + context.length - msgRenderIndex : -1;

  const autoFocus = !isMobileScreen; // wont auto focus on mobile screen

  const changeLikeStatus = (message: Message, type: 'like' | 'dislike') => {
    if (type === 'like') {
      if (message.likeStatus === 1) {
        chatStore.onMessageLike(message, 0);
      } else {
        chatStore.onMessageLike(message, 1);
        logEvent('message_feedback', { message_id: message.id, type: 'like' });
      }
    }

    if (type === 'dislike') {
      if (message.likeStatus === -1) {
        chatStore.onMessageLike(message, 0);
      } else {
        chatStore.onMessageLike(message, -1);
        logEvent('message_feedback', { message_id: message.id, type: 'dislike' });
      }
    }
  };

  useCommand({
    fill: setUserInput,
    submit: (text) => {
      doSubmit(text);
    },
    code: (text) => {
      console.log('[Command] got code from url: ', text);
      showConfirm(t('URLCommand.Code', { code: text }), t).then((res) => {
        if (res) {
          accessStore.updateCode(text);
        }
      });
    },
    settings: () => {},
  });

  // remember unfinished input
  useEffect(() => {
    // try to load from local storage
    const key = UNFINISHED_INPUT(session.id);
    const mayBeUnfinishedInput = localStorage.getItem(key);
    if (mayBeUnfinishedInput && userInput.length === 0) {
      setUserInput(mayBeUnfinishedInput);
      localStorage.removeItem(key);
    }

    const dom = inputRef.current;
    return () => {
      localStorage.setItem(key, dom?.value ?? '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.chat} key={session.id}>
      <IssueBanner />

      <div
        className={clsx(styles['chat-body'], { [styles['chat-body--empty']]: !messages.length })}
        ref={scrollRef}
        onScroll={(e) => onChatBodyScroll(e.currentTarget)}
        onMouseDown={() => inputRef.current?.blur()}
        onTouchStart={() => {
          inputRef.current?.blur();
          setAutoScroll(false);
        }}
      >
        {messages.length ? (
          messages.map((message, i) => {
            const isUser = message.role === 'user';
            const isContext = i < context.length;
            // const showActions = i > 0 && !(message.preview || message?.message?.content?.length === 0) && !isContext;
            const showTyping = message.preview || message.streaming;

            const shouldShowClearContextDivider = i === clearContextIndex - 1;

            return (
              <Fragment key={message.id}>
                <div className={isUser ? styles['chat-message-user'] : styles['chat-message']}>
                  <div className={styles['chat-message-container']}>
                    <div className={styles['chat-message-header']}>
                      {isUser ? (
                        <div className={styles['chat-message-avatar']}>
                          {/*<div className='user-avatar user-avatar--reverted'>*/}
                          {/*  <img src={userAvatar} alt='emoji' width={20} height={20} />*/}
                          {/*</div>*/}
                        </div>
                      ) : (
                        <div className={styles['chat-message-avatar']}>
                          <div className='user-avatar'>
                            <GenieGptIcon width={18} height={18} />
                          </div>
                        </div>
                      )}
                      <div className={styles['chat-message-actions']}>
                        <div className={styles['chat-input-actions']}>
                          {!isUser && (
                            <>
                              <ChatAction
                                text='Like'
                                icon={<LikeIcon width={15} height={15} />}
                                onClick={() => message.message && changeLikeStatus(message.message, 'like')}
                                filled={message?.message?.likeStatus === 1}
                              />
                              <ChatAction
                                text='Dislike'
                                icon={
                                  <div className='rotate-180'>
                                    <LikeIcon width={15} height={15} />
                                  </div>
                                }
                                onClick={() => message.message && changeLikeStatus(message.message, 'dislike')}
                                filled={message?.message?.likeStatus === -1}
                              />
                            </>
                          )}
                          {/* <ChatAction text={t('Chat.Actions.Copy')} icon={<CopyIcon />} onClick={() => {
                            copyToClipboard(message.message?.content || '', t);
                            logEvent('message_copy', { message_id: message.id, source: isUser ? 'user' : 'bot' });
                          }} /> */}
                        </div>
                      </div>
                    </div>
                    {showTyping && <div className={styles['chat-message-status']}>{t('Chat.Thinking')}</div>}
                    <div className={clsx(styles['chat-message-item'], { [styles['chat-message-item-answer']]: !isUser })}>
                      <Markdown
                        content={message.message?.content || ''}
                        loading={(message.preview || message.streaming) && (!message.message?.content || message.message?.content.length === 0) && !isUser}
                        onContextMenu={(e) => onRightClick(e, message)}
                        onDoubleClickCapture={() => {
                          if (!isMobileScreen) return;
                          setUserInput(message.message?.content || '');
                        }}
                        fontSize={fontSize}
                        parentRef={scrollRef}
                        defaultShow={i >= messages.length - 6}
                      />
                    </div>
                    <div className={styles['chat-message-action-date']}>{message.date.toLocaleString()}</div>
                  </div>
                </div>
                {shouldShowClearContextDivider && <ClearContextDivider />}
              </Fragment>
            );
          })
        ) : (
          <Fragment>
            <EmptyChat />
            <QuickPrompts />
          </Fragment>
        )}
      </div>

      <div className={styles['chat-input-panel']}>
        <PromptHints prompts={promptHints} onPromptSelect={onPromptSelect} />

        <div className={styles['chat-input-panel-inner']}>
          <textarea
            ref={inputRef}
            className={styles['chat-input']}
            placeholder={t('Chat.Input')}
            onInput={(e) => onInput(e.currentTarget.value)}
            value={userInput}
            onKeyDown={onInputKeyDown}
            onFocus={scrollToBottom}
            onClick={scrollToBottom}
            rows={inputRows}
            autoFocus={autoFocus}
            style={{
              fontSize: config.fontSize,
            }}
          />
          <Button
            disabled={botMessageLoading}
            className='!absolute top-[22px] right-5 flex justify-center items-center gap-x-1 text-gray-100 !px-4'
            onClick={() => doSubmit(userInput)}
          >
            <SendIcon /> <Text size='sm'>{t('Chat.Send')}</Text>
          </Button>
        </div>
      </div>
    </div>
  );
}

export function Chat() {
  const chatStore = useChatStore();
  const sessionIndex = chatStore.currentSessionIndex;
  return <_Chat key={sessionIndex} />;
}
