import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { useEffect, useRef } from 'react';
import { ReactComponent as DeleteIcon } from '../icons/delete.svg';
import { useChatStore } from '../store';
import { Mask } from '../store/mask';
import styles from './home.module.scss';
import { showConfirm } from './ui-lib';
import { useLogEvent } from '@root/shared/utils/hooks/use-log-event';
import { useTranslation } from 'react-i18next';

export function ChatItem(props: {
  onClick?: () => void;
  onDelete?: () => void;
  title: string;
  count: number;
  time: string;
  selected: boolean;
  id: string;
  index: number;
  narrow?: boolean;
  mask: Mask;
}) {
  const draggableRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('master-chat');

  useEffect(() => {
    if (props.selected && draggableRef.current) {
      draggableRef.current?.scrollIntoView({
        block: 'center',
      });
    }
  }, [props.selected]);

  return (
    <Draggable draggableId={`${props.id}`} index={props.index}>
      {(provided) => (
        <div
          className={`${styles['chat-item']} ${props.selected && styles['chat-item-selected']}`}
          onClick={props.onClick}
          ref={(ele) => {
            draggableRef.current = ele;
            provided.innerRef(ele);
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          title={`${props.title}\n${t('ChatItem.ChatItemCount', { count: props.count })}`}
        >
          {props.narrow ? (
            <div className={styles['chat-item-narrow']}>
              <div className={styles['chat-item-avatar'] + ' no-dark'} />
              <div className={styles['chat-item-narrow-count']}>{props.count}</div>
            </div>
          ) : (
            <>
              <div className={styles['chat-item-title']}>{props.title}</div>
              <div className={styles['chat-item-info']}>
                <div className={styles['chat-item-count']}>{t('ChatItem.ChatItemCount', { count: props.count })}</div>
                <div className={styles['chat-item-date']}>{props.time}</div>
              </div>
            </>
          )}
          <div className={styles['chat-item-delete']} onClickCapture={props.onDelete}>
            <DeleteIcon />
          </div>
        </div>
      )}
    </Draggable>
  );
}

export function ChatList(props: { narrow?: boolean }) {
  const [sessions, selectedIndex, selectSession, moveSession] = useChatStore((state) => [state.sessions, state.currentSessionIndex, state.selectSession, state.moveSession]);
  const chatStore = useChatStore();

  const onDragEnd: OnDragEndResponder = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    moveSession(source.index, destination.index);
  };

  const { t, i18n } = useTranslation('master-chat');

  const { logEvent } = useLogEvent();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='chat-list'>
        {(provided) => (
          <div className={styles['chat-list']} ref={provided.innerRef} {...provided.droppableProps}>
            {sessions.map((item, i) => (
              <ChatItem
                title={item.topic}
                time={new Date(item.lastUpdate).toLocaleString()}
                count={item.messages.length}
                key={item.id}
                id={item.id}
                index={i}
                selected={i === selectedIndex}
                onClick={() => {
                  selectSession(i);
                  logEvent('chat_select', { chat_id: item.id });
                }}
                onDelete={async () => {
                  if (!props.narrow || (await showConfirm(t('Home.DeleteChat'), t))) {
                    chatStore.deleteSession(i, t, i18n.language);
                  }
                }}
                narrow={props.narrow}
                mask={item.mask}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
